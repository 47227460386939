export const PRODUCTION_BACK_URL = 'https://rockettacrmapi.azurewebsites.net/';
export const DEVELOPMENT_BACK_URL = 'https://localhost:44395/';

export const CLIENT_ID = '20a37b70-c816-4bb3-b18f-d5d78ab19eb4';
export const TENANT_ID = 'https://login.microsoftonline.com/aed3f71f-cc07-482b-81fe-013321550d8e';
export const API_SCOPE = 'api://b282ca76-455c-4a6a-aaf3-7cae806e2ab5/api.scope';

export const ENG_QUOTATION_TEXT_ABOVE = `<p>Dear Madam or Sir</p>please find below as requested our quotation for the project:`;
export const ENG_INVOICE_TEXT_ABOVE = `<p>Dear Madam or Sir</p>please find below the invoiced efforts for the project:`;

export const GER_QUOTATION_TEXT_ABOVE = `<p>Sehr geehrte Damen und Herren,</p>vielen Dank für Ihre Anfrage. Hiermit bieten wir Ihnen wie folgt an:`;
export const GER_QUOTATION_TEXT_BELOW = `<p>Die aufgeführten Preise sind Festpreise und verstehen sich als Nettopreise zzgl. USt.</p>Mit freundlichen Grüßen<p>Maxim Tarasenko</p>`;

export const GER_INVOICE_TEXT_ABOVE = `<p>Sehr geehrte Damen und Herren,</p><br>basierend auf unserem Angebot und Ihrer Bestellung stellen wir Ihnen folgende Leistungen in Rechnung:`;
export const GER_INVOICE_TEXT_BELOW = `<p>Der Rechnungsbetrag ist innerhalb von 30 Tagen nach Rechnungsstellung ohne Abzug fällig.</p><br>Mit freundlichen Grüßen<p>Maxim Tarasenko</p>`;

export const GER_TEST_DESCRIPTION = "<p>Testen und Ausrollen</p>";
export const GER_MANAGEMENT_DESCRIPTION = "<p>Projektmanagement</p>";

export const ENG_TEXT_BELOW = `<p>All the prices in the quotation are net and subject to VAT at the standard rate.</p><p style="text-align: start">Invoice amounts are payable within 30 days after the invoice date without deduction.</p><p style="text-align: start"></p><p style="text-align: start">For further information or if you have any questions, please do not hesitate to contact us.</p><p style="text-align: start"></p><p style="text-align: start">Best regards</p><p style="text-align: start">Maxim Tarasenko</p>`; 
export const ENG_TEST_DESCRIPTION = "<p>Testing and bug fixing</p>";
export const ENG_MANAGEMENT_DESCRIPTION = "<p>Project management</p>";

export const DEFAULT_DESCRIPTION = "<p></p>";

export const TEST_PERCENTAGE = 20;
export const MANAGEMENT_PERCENTAGE = 20;
export const TAX = 19;
export const WORKING_HOURS_IN_DAY = 8;

export const UNIT_TIME_INTERVAL = "Unit";
export const DAY_TIME_INTERVAL = "Day";
export const HOUR_TIME_INTERVAL = "Hour";
export const CUSTOM_TIME_INTERVAL = "Custom";

export const MAX_PERCENTAGE = 100;
export const MAX_PRICE = 100000000;
export const MAX_QTY = 1000;

export const forbiddenCharactersRegex = /^[^~)('!*<>:;,?"*\\|/]+$/;
export const forbiddenNamesMessage = `Name should not be empty and contains ^~)('!*<>:;?,\\"*|/ characters`;