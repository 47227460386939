import * as React from 'react';

import { Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import { ICustomerPostRequest } from 'src/api/customer';
import { buttonColor } from 'src/common/button-style';
import { ICustomerDto, Language } from 'src/common/dto';
import { forbiddenNamesMessage, MAX_PERCENTAGE, TAX } from 'src/common/constants';
import { IsForbiddenName } from 'src/common/functions';

interface CustomerFormProps {
    addMode?: {
        addCustomer: (customer: ICustomerPostRequest, language: Language) => void
    }
    editMode?: {
        customer: ICustomerDto
        editCustomer: (id: number, customer: ICustomerPostRequest, language: Language) => void
        setCustomerState: (customer: ICustomerDto) => void
    }
    buttonText: string,
    className?: string
}

interface CustomerFormState {
    customer: {
        name: string;
        contractor: string;
        defaultPricePerDay: number;
        language: Language,
        tax: number,
    }
    errorMessages: {
        nameRequired: string;
    }
    shownPrice: string
}

class CustomerForm extends React.Component<CustomerFormProps, CustomerFormState> {
    constructor(props: CustomerFormProps) {
        super(props);
        this.state = {
            customer: {
                name: this.props.editMode?.customer.name || '',
                contractor: this.props.editMode?.customer.contractor || '',
                defaultPricePerDay: this.props.editMode?.customer.defaultPricePerDay || 0,
                language: this.props.editMode?.customer.language || Language[Language.German] as unknown as Language,
                tax: this.props.editMode?.customer.tax || TAX,
            },
            errorMessages: {
                nameRequired: ''
            },
            shownPrice: this.props.editMode?.customer.defaultPricePerDay.toLocaleString("De-de") || '0',
        };
    }

    sendRequest = (): void => {
        const customer: ICustomerPostRequest = {
            name: this.state.customer.name,
            contractor: this.state.customer.contractor !== '' ? this.state.customer.contractor : null,
            defaultPricePerDay: this.state.customer.defaultPricePerDay,
            tax: this.state.customer.tax,
        }
        if (this.props.addMode) {
            this.props.addMode.addCustomer(customer, this.state.customer.language)
        }
        else if (this.props.editMode) {
            customer.cc = this.props.editMode.customer.cc;
            customer.to = this.props.editMode.customer.to;
            customer.text = this.props.editMode.customer.text;
            this.props.editMode.editCustomer(this.props.editMode.customer.id, customer, this.state.customer.language)
        }
    }

    isButtonDisable = (): boolean => {
        const customer = {
            name: this.state.customer.name,
            contractor: this.state.customer.contractor !== '' ? this.state.customer.contractor : null,
            language: this.state.customer.language,
            defaultPricePerDay: this.state.customer.defaultPricePerDay,
            tax: this.state.customer.tax,
        }

        if (IsForbiddenName(customer.name))
            return true;
        else if (customer.name !== this.props.editMode?.customer.name)
            return false;
        else if (customer.contractor !== this.props.editMode?.customer.contractor)
            return false;
        else if (customer.language !== this.props.editMode?.customer.language)
            return false;
        else if (customer.defaultPricePerDay !== this.props.editMode?.customer.defaultPricePerDay)
            return false;
        else if (customer.tax !== this.props.editMode?.customer.tax)
            return false;

        else return true;
    }

    setCustomerName = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const customer = this.state.customer;
        customer.name = newValue || ''
        const errorMessages = this.state.errorMessages;
        if (IsForbiddenName(customer.name))
            errorMessages.nameRequired = forbiddenNamesMessage
        else
            errorMessages.nameRequired = ''
        this.setState({ customer: customer, errorMessages: errorMessages })
    }

    setContractorName = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const customer = this.state.customer;
        customer.contractor = newValue || ''
        this.setState({ customer: customer })
    }

    changeTax = (event: React.SyntheticEvent<HTMLElement, Event>, newValue: string | undefined): void => {
        const customer = this.state.customer;
        if (customer) {
            customer.tax = Number(newValue)
            if (!isNaN(customer.tax) && customer.tax <= MAX_PERCENTAGE)
                this.setState({ customer })
        }
    }

    changeLanguage = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined): void => {
        const customer = this.state.customer;
        customer.language = option?.text as unknown as Language
        this.setState({ customer: customer })
    }

    changePrice = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined): void => {
        if (newValue !== undefined) {
            newValue = newValue?.replace(',', '.')

            if (!isNaN(Number(newValue)) && newValue.length <= Number(newValue).toFixed(2).length) {
                if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
                    newValue = newValue.substring(1, newValue.length);
                }

                const customer = this.state.customer;
                customer.defaultPricePerDay = Number(newValue);
                const shownPrice = newValue.replace('.', ',');
                this.setState({ customer: customer, shownPrice: shownPrice });
            }
        }
    }

    onPriceFocus = (): void => {
        const shownPrice = this.state.shownPrice.replaceAll('.', '');
        this.setState({ shownPrice: shownPrice })
    }

    onPriceBlur = (): void => {
        const shownPrice = this.state.customer.defaultPricePerDay.toLocaleString("De-de");
        this.setState({ shownPrice: shownPrice })
    }

    render(): React.ReactElement<CustomerForm> {
        return (
            <div className={this.props.className}>
                <div className='flex flex-col gap-4 w-80'>
                    <TextField
                        label='Name' required
                        onChange={this.setCustomerName}
                        value={this.state.customer.name}
                        errorMessage={this.state.errorMessages.nameRequired}
                    />

                    <TextField
                        label='Contractor'
                        onChange={this.setContractorName}
                        value={this.state.customer.contractor}
                    />

                    <TextField
                        label='Tax'
                        suffix='%'
                        value={this.state.customer.tax.toString()}
                        onChange={this.changeTax}
                    />

                    <TextField
                        label='Default price per day'
                        suffix='€'
                        value={this.state.shownPrice}
                        onChange={this.changePrice}
                        onBlur={this.onPriceBlur}
                        onFocus={this.onPriceFocus}
                    />

                    <Dropdown
                        label='Language'
                        options={[
                            { key: Language.English, text: Language[0] },
                            { key: Language.German, text: Language[1] }
                        ]}
                        selectedKey={Language[this.state.customer.language]}
                        onChange={this.changeLanguage}
                    />
                </div>

                <PrimaryButton
                    onClick={this.sendRequest}
                    className={buttonColor(this.isButtonDisable()) + ' mt-6'}
                    text={this.props.buttonText}
                    disabled={this.isButtonDisable()}
                />
            </div>
        );
    }
}

export default CustomerForm;