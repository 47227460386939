import { AxiosResponse } from "axios";
import { WORKING_HOURS_IN_DAY, DAY_TIME_INTERVAL, HOUR_TIME_INTERVAL, DEFAULT_DESCRIPTION, ENG_INVOICE_TEXT_ABOVE, ENG_TEXT_BELOW, GER_INVOICE_TEXT_ABOVE, GER_INVOICE_TEXT_BELOW, ENG_QUOTATION_TEXT_ABOVE, GER_QUOTATION_TEXT_ABOVE, GER_QUOTATION_TEXT_BELOW, forbiddenCharactersRegex } from "./constants"
import { DocType, IModuleDto, IPaymentDto, IProjectDto, Language, ModuleType } from "./dto"
import { IQuotationPostDto } from "src/api/quotation";
import { IInvoicePostDto } from "src/api/invoice";

export function isDevelopment(url: string): boolean {
    return url.indexOf('localhost:3000') !== -1
}

export function isEnglishLanguage(language: Language): boolean {
    return language === Language[Language.English] as unknown as Language
}

export function showModuleName(html: string): string {
    return html.replace(/<[^>]+>/g, ' ');
}

export function calcNetPrice(modules: IModuleDto[], type: DocType): number {
    let totalPrice = 0;

    modules.forEach(m => {
        if (!m.isOptional || type === DocType.Invoice)
            totalPrice += m.price * m.quantity
    });

    return totalPrice;
}

export function calcModuleDays(modules: IModuleDto[], type: DocType): number {
    let days = 0;

    modules.forEach(m => {
        if (!m.isOptional || type === DocType.Invoice) {
            if (m.timeInterval === HOUR_TIME_INTERVAL)
                days += m.quantity / WORKING_HOURS_IN_DAY;
            else if (m.timeInterval === DAY_TIME_INTERVAL)
                days += m.quantity;
        }
    })

    return days;
}

export function showPrice(price: number): string {
    return price.toLocaleString("De-de", {
        minimumFractionDigits: 2, maximumFractionDigits: 2
    }) + '€';
}

export function showDate(date: Date): string {
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).replace(/\//g, '.');

    return formattedDate;
}

export function parseStringToNumber(numberString: string): number {
    return +(numberString.replace('€', '').replaceAll('.', '').replace(',', '.'))
}

export function calcPercentage(qty: number, timeInterval: string, modules: IModuleDto[]): number {
    const days = calcModuleDays(modules.filter(m => m.type === ModuleType[ModuleType.Module]), DocType.Invoice);
    const totalQty = timeInterval === HOUR_TIME_INTERVAL ? days * WORKING_HOURS_IN_DAY : days;
    if (totalQty === 0)
        return 0;
    return Number((qty / totalQty * 100).toFixed(0));
}

export function calcQtyByPercent(percentage: number, timeInterval: string, modules: IModuleDto[]): number {
    const days = calcModuleDays(modules.filter(m => m.type === ModuleType[ModuleType.Module]), DocType.Invoice);
    const totalQty = timeInterval === HOUR_TIME_INTERVAL ? days * WORKING_HOURS_IN_DAY : days;
    return Number((totalQty / 100 * percentage).toFixed(1));
}

export function downloadFile(response: AxiosResponse): void {
    const contentDispositionHeader = response.headers['content-disposition'];
    const parts = contentDispositionHeader.split(';');
    const fileNamePart = parts.find(part => part.trim().startsWith('filename='));
    const fileName = fileNamePart && fileNamePart.split('=')[1].trim().replace(/"/g, '');

    if (fileName) {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response.data);
        link.download = decodeURIComponent(fileName);
        link.click()
    }
}

export function IsForbiddenName(name: string): boolean {
    return name.trim() === '' || !forbiddenCharactersRegex.test(name)
}

export function getPaymentsPrice(payments: IPaymentDto[]): number {
    const price = payments.reduce((paymentsPrice, payment) => paymentsPrice + payment.price, 0);
    return +price.toFixed(2);
}

export function setDocumentTitle(value: string): void {
    document.title = `CRM - ${value}`
}

export function setTextAboveAndBelow(project: IProjectDto, invoice?: IInvoicePostDto, quotation?: IQuotationPostDto): void {
    const isEnglish = isEnglishLanguage(project.language);

    if (invoice) {
        const defaultProjectTextAbove = project.defaultInvoiceTextAbove && project.defaultInvoiceTextAbove !== DEFAULT_DESCRIPTION ? project.defaultInvoiceTextAbove : null;
        const defaultProjectTextBelow = project.defaultInvoiceTextBelow && project.defaultInvoiceTextBelow !== DEFAULT_DESCRIPTION ? project.defaultInvoiceTextBelow : null;
        const defaultCustomerTextAbove = project.customerDefaultInvoiceTextAbove && project.customerDefaultInvoiceTextAbove !== DEFAULT_DESCRIPTION ? project.customerDefaultInvoiceTextAbove : null;
        const defaultCustomerTextBelow = project.customerDefaultInvoiceTextBelow && project.customerDefaultInvoiceTextBelow !== DEFAULT_DESCRIPTION ? project.customerDefaultInvoiceTextBelow : null;

        if (isEnglish) {
            invoice.textAbove = defaultProjectTextAbove ? defaultProjectTextAbove : defaultCustomerTextAbove ? defaultCustomerTextAbove : ENG_INVOICE_TEXT_ABOVE;
            invoice.textBelow = defaultProjectTextBelow ? defaultProjectTextBelow : defaultCustomerTextBelow ? defaultCustomerTextBelow : ENG_TEXT_BELOW;
        }
        else {
            invoice.textAbove = defaultProjectTextAbove ? defaultProjectTextAbove : defaultCustomerTextAbove ? defaultCustomerTextAbove : GER_INVOICE_TEXT_ABOVE;
            invoice.textBelow = defaultProjectTextBelow ? defaultProjectTextBelow : defaultCustomerTextBelow ? defaultCustomerTextBelow : GER_INVOICE_TEXT_BELOW;
        }
    }
    if (quotation) {
        const defaultProjectTextAbove = project.defaultQuotationTextAbove && project.defaultQuotationTextAbove !== DEFAULT_DESCRIPTION ? project.defaultQuotationTextAbove : null;
        const defaultProjectTextBelow = project.defaultQuotationTextBelow && project.defaultQuotationTextBelow !== DEFAULT_DESCRIPTION ? project.defaultQuotationTextBelow : null;
        const defaultCustomerTextAbove = project.customerDefaultQuotationTextAbove && project.customerDefaultQuotationTextAbove !== DEFAULT_DESCRIPTION ? project.customerDefaultQuotationTextAbove : null;
        const defaultCustomerTextBelow = project.customerDefaultQuotationTextBelow && project.customerDefaultQuotationTextBelow !== DEFAULT_DESCRIPTION ? project.customerDefaultQuotationTextBelow : null;

        if (isEnglish) {
            quotation.textAbove = defaultProjectTextAbove ? defaultProjectTextAbove : defaultCustomerTextAbove ? defaultCustomerTextAbove : ENG_QUOTATION_TEXT_ABOVE;
            quotation.textBelow = defaultProjectTextBelow ? defaultProjectTextBelow : defaultCustomerTextBelow ? defaultCustomerTextBelow : ENG_TEXT_BELOW;
        }
        else {
            quotation.textAbove = defaultProjectTextAbove ? defaultProjectTextAbove : defaultCustomerTextAbove ? defaultCustomerTextAbove : GER_QUOTATION_TEXT_ABOVE;
            quotation.textBelow = defaultProjectTextBelow ? defaultProjectTextBelow : defaultCustomerTextBelow ? defaultCustomerTextBelow : GER_QUOTATION_TEXT_BELOW;
        }
    }
}